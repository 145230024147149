import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { AppConstants } from 'src/app/common/app.constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isLoggedIn = false;
  displayName: string;
  avatar: string;
  codeContest = '';
  roles: string[];
  showHuyenTPBoard = false;
  showAdminBoard = false;
  showModBoard = false;
  email: string;

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router, private tokenStorageService: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.displayName = user.displayName;
      this.email = user.email;
      this.avatar = AppConstants.API_URL2 + user.avatar;
      this.roles = user.roles;
      this.showModBoard = this.roles.includes('ROLE_MODERATOR');
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
    }
    // let desktopRole = localStorage.getItem('desktopRole')
    // if (desktopRole === 'admin') {
    //   this.navItems = navItemsAdmin;
    // }
  }

  logout(): void {
    this.tokenStorageService.signOut();
    this.router.navigate(['/auth/login'])
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');

    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login']);
    }
  }

}
